<!--物料列表-->

<template>
  <div class="widgets">
    <div class="wrap">
      <draggable class="" v-model="initializingList" v-bind="{ group: { name: 'itxst', pull: 'clone' }, sort: false }"
        :clone="handleClone" :end="handleEnd" animation="300">
        <div v-for="(item, index) in initializingList" :key="`${item.name}_${index}`" class="item">
          <img class="img" :src="getUrl(item.icon)" alt="">
          <!-- <i class="icon" :class="item.icon"></i> -->
          <span>{{ item.name }}</span>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import url from '../../assets/image/schema/countdown.png'
export default {
  inject: ["control"],
  data() {
    return {
      initializingList: Object.values(this.$initializing),
    }
  },
  methods: {
    getUrl(val) {
      const _url = require(`../../assets/image/schema/${val}.png`)
      return _url
    },
    handleClone(e) {
      const _e = JSON.parse(JSON.stringify(e))
      return {
        ..._e,
        id: this.$getRandomCode(6)
      }
    },
    handleEnd(e) {
      console.log(e)
    }
  },
};
</script>

<style lang="scss" scoped>
.widgets {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 190px;
  overflow-y: auto;
}

.wrap {
  background-color: #fff;
  min-height: 100%;
}

.item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 76px;
  height: 76px;
  border-radius: 4px;
  margin-left: 12px;
  /*no*/
  margin-bottom: 8px;
  // margin-top: 10px;
  /*no*/
  font-size: 12px;
  /*no*/
  color: #666;
  cursor: pointer;
  border: 1px solid #fff;
  padding: 5px 0;

  .img {
    width: 26px;
    height: 26px;
    margin-bottom: 10px;
    object-fit: scale-down;
    overflow: hidden;
  }

  i {
    font-size: 29px;
    /*no*/
    margin-top: 5px;
    /*no*/
    margin-bottom: 10px;

    /*no*/
    &[class*=" el-icon-"],
    [class^=el-icon-] {
      font-family: element-icons !important;
    }
  }

  &:hover {
    border-color: $color-theme;
    // color: #fff;
    // background: $color-theme;
  }
}
</style>
