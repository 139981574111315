<!--页面构建-->
<template>
  <div class="build">
    <control />
  </div>
</template>

<script>
import Control from "@/components/Control";
import { mapMutations } from "vuex";
export default {
  components: {
    Control,
  },
  methods: {
    ...mapMutations(['queryFixedPages']),
  },
  mounted() {
    this.queryFixedPages()
  }
};
</script>
<style lang="scss" scoped>
.build {
  height: 100vh;
  background: #f7f8fa;
}
</style>
