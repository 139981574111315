
<template>
  <div class="control">
    <control-widgets />
    <control-panel />
    <control-config />
  </div>
</template>

<script>

import ControlWidgets from "./ControlWidgets.vue";
import ControlPanel from "./ControlPanel.vue";
import ControlConfig from "./ControlConfig.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "control",

  components: {
    ControlWidgets,
    ControlPanel,
    ControlConfig,
  },

  provide() {
    return {
      control: this,
    };
  },

  data() {
    return {
      dragStatus: false,
      curWidget: null,
      dragWidget: null,
    };
  },

  computed: {
    ...mapGetters(["curPage"]),
  },
};
</script>

<style lang='scss'>
.ghost-widget {
  position: relative;
  width: 100%;
  height: 40px;

  /*no*/
  &.item {
    width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/image/ghost.jpg');
    z-index: 10000;
  }

  &::after {
    content: '组件放置区域';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
    height: 30px;
    line-height: 30px;
    background: #5487df;
    font-size: 12px
      /*no*/
    ;
    color: #fff;
    text-align: center;
    pointer-events: none;
    z-index: 100001;
  }
}

.control {
  position: relative;
  height: calc(100% - 56px);
  overflow: hidden;
  min-width: 1200px;
}
</style>