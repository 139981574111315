<!--画板组件容器 （配置工具栏）-->
<template>
  <div class="shape" :class="{ 'isHide': isHide }" @click="setCurComponent(data)">
    <!-- 选中组件高亮 -->
    <div v-if="isCurComponent(data.id)" class="shape-solid event-none"></div>
    <div class="shape-dashed event-none"></div>
    <!-- 组件工具栏 -->
    <div v-if="data.component != 'waiting'" class="shape-tab">
      <!-- 删除 -->
      <template v-if="control.curWidget && control.curWidget.id == data.id">
        <i class="icon icon-shanchu tab-icon f16" @click.stop="delComponent(data.id)"></i>
        <i class="el-icon-view tab-icon f16 ml16" @click.stop="hideComponent(data.id)"></i>
      </template>
      <!-- 组件名 -->
      <span v-else>{{ data.name }}</span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "shape",

  inject: ["control"],

  props: {
    data: {
      type: Object,
      default: () => { }
    },
  },
  computed: {
    isHide() {
      const _tag = this.control.curPage.componentList.find(f => f.id == this.data.id)
      return _tag.isHide
    }
  },
  methods: {
    // 修改选中物料
    setCurComponent() {
      console.log('选中修改组件_', this.data)
      // this.$emit("changeCurrWidget", this.data);
      this.control.curWidget = this.data
    },

    // 删除物料
    delComponent(id) {
      let index = this.control.curPage.componentList.findIndex(
        (item) => item.id == id
      );
      this.control.curPage.componentList.splice(index, 1);
      this.control.curWidget = undefined;
    },
    hideComponent(id) {
      const _index = this.control.curPage.componentList.findIndex(
        (item) => item.id == id
      )
      const _tag = this.control.curPage.componentList?.find(f => f.id == id)
      _tag.isHide = !_tag.isHide
      this.control.curPage.componentList.splice(_index, 1, _tag)
    },
    isCurComponent(id) {
      return this.control?.curWidget?.id == id
    },
  },
};
</script>

<style lang="scss" scoped>
.shape {
  position: relative;

  &.isHide {
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: auto;
      background-color: rgba(0, 0, 0, .2);
      background-image: url(../../assets/image/hide.png);
      background-size: 80px 25px;
      background-position: 50%;
      background-repeat: no-repeat;
      z-index: 10;
    }

  }

  &:hover {
    .shape-dashed {
      display: block;
    }
  }

  .shape-dashed {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: dashed 1px $color-theme;
    z-index: 100;
  }

  .shape-solid {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 2px $color-theme;
    z-index: 100;
  }

  .shape-tab {
    position: absolute;
    right: -86px;
    /*no*/
    top: 50%;
    /*no*/
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    width: 80px;
    /*no*/
    height: 24px;
    /*no*/
    font-size: 12px;
    /*no*/
    color: #333;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &::after {
      content: "";
      position: absolute;
      right: 100%;
      /*no*/
      top: 7px;
      /*no*/
      width: 0;
      /*no*/
      height: 0;
      /*no*/
      border-width: 5px;
      /*no*/
      border-style: solid;
      border-color: transparent;
      margin-bottom: -1px;
      /*no*/
      border-right-width: 5px;
      /*no*/
      border-right-color: currentColor;
      color: #fff;
    }
  }

  .event-none {
    pointer-events: none;
  }

  .tab-icon {
    color: #969799;
    cursor: pointer;

    &:hover {
      color: #87888a;
    }
  }
}
</style>