<!--页面配置-->
<template>
  <div>
    <div class="wrap">
      <div class="flex" style="align-items: center; padding-left: 30px;">
        <span class="w70 pr10 f13 f-grey">页面标题</span>
        <p class="ellipsis-1 f18" style="color: #323233;font-weight: 550; flex: 1;">{{ curPage.name }}</p>
      </div>
      <div class="flex" style="align-items: center; padding: 10px 0 10px 30px;">
        <span class="w70 pr10 f13 f-grey">页面描述</span>
        <p class="f14 f-grey ellipsis-1" style="flex: 1;">{{ curPage.remote }}</p>
      </div>

      <div class="wrap-label">
        <span>背景设置</span>
      </div>

      <div class="wrap-body">
        <div class="p10">
          <el-radio-group v-model="curPage.customize" @input="handleCustomize">
            <el-radio :label="false">无</el-radio>
            <el-radio :label="true">自定义背景</el-radio>
          </el-radio-group>
        </div>
        <template v-if="curPage.customize">
          <div class="p10 flex">
            <span class="w70 f13 f-grey">背景色</span>
            <el-color-picker style="margin-top: 0;" v-model="curPage.ThemeColor" :predefine="predefineColors"
              show-alpha></el-color-picker>
          </div>
          <SchemaUpload label="背景图片" v-model="curPage.ThemeImg"></SchemaUpload>
        </template>
      </div>
    </div>
    <div class="wrap">
      <div class="wrap-label">
        <span>头部设置</span>
      </div>

      <div class="wrap-body">
        <div class="p10 flex">
          <span class="w100 f13 f-grey">顶部栏显示方式</span>
          <el-radio-group v-model="curPage.HeaderVisibleMode">
            <el-radio :label="0">固定显示</el-radio>
            <el-radio :label="1">滑动时显示</el-radio>
            <el-radio :label="2">不显示</el-radio>
          </el-radio-group>
        </div>
        <div class="p10 flex">
          <span class="w100 f13 f-grey">文字颜色</span>
          <el-radio-group v-model="curPage.textColor">
            <el-radio label="#000">黑色</el-radio>
            <el-radio label="#fff">白色</el-radio>
          </el-radio-group>
        </div>
        <div class="p10 flex">
          <span class="w100 f13 f-grey">头部背景</span>
          <div class="set-wrap">
            <el-radio-group v-model="curPage.headerBgType" @input="curPage.headerBg = ''">
              <el-radio label="3">主题色</el-radio>
              <el-radio label="1">自定义颜色</el-radio>
              <el-radio label="2">背景图片</el-radio>
            </el-radio-group>
            <div style="height: 92px;">
              <template v-if="curPage.headerBgType == '1'">
                <el-color-picker v-model="curPage.headerBg" :predefine="predefineColors" show-alpha></el-color-picker>
              </template>
              <template v-else-if="curPage.headerBgType == '2'">
                <SchemaUpload v-model="curPage.headerBg"></SchemaUpload>
              </template>
            </div>
          </div>
        </div>

        <div class="p10 flex">
          <span class="w100 f13 f-grey">滚动后头部背景</span>
          <div class="set-wrap">
            <el-radio-group v-model="curPage.headerBgAfterType" @input="curPage.headerBgAfter = ''">
              <el-radio label="3">主题色</el-radio>
              <el-radio label="1">自定义颜色</el-radio>
              <el-radio label="2">背景图片</el-radio>
            </el-radio-group>
            <div style="height: 92px;">
              <template v-if="curPage.headerBgAfterType == '1'">
                <el-color-picker v-model="curPage.headerBgAfter" :predefine="predefineColors"
                  show-alpha></el-color-picker>
              </template>
              <template v-else-if="curPage.headerBgAfterType == '2'">
                <SchemaUpload v-model="curPage.headerBgAfter"></SchemaUpload>
              </template>
            </div>
          </div>
        </div>

        <config-item label="标题方式">
          <el-radio-group class="pt10" v-model="curPage.TitleMode" @input="handleChangeTitleMode">
            <el-radio label="1">文字</el-radio>
            <el-radio label="2">图片</el-radio>
            <el-radio label="3">不显示</el-radio>
          </el-radio-group>
        </config-item>

        <config-item label="标题文字" v-show="curPage.TitleMode == '1'">
          <el-radio-group class="pt10" v-model="curPage.ShowMartNameType" @input="handleChangeNameType">
            <el-radio label="1">店铺名称</el-radio>
            <el-radio label="2">页面名称</el-radio>
            <el-radio label="3">自定义</el-radio>
          </el-radio-group>
          <el-input class="pt10" placeholder="请输入自定义标题" v-model="curPage.martName"
            :disabled="curPage.ShowMartNameType != '3'"></el-input>
        </config-item>

        <config-item label="搜索栏">
          <el-radio-group class="pt10" v-model="curPage.ShowSearchBox">
            <el-radio label="1">显示</el-radio>
            <el-radio label="2">不显示</el-radio>
            <el-radio label="3">滚动时显示</el-radio>
          </el-radio-group>
        </config-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PageConfig",
  computed: {
    ...mapGetters(["curPage", "project"]),
  },
  data() {
    return {
      martName: '',
      predefineColors: [
        "#ffffff",
        "#f5f5f5f5",
        "#F2F2F2",
        "#FF4444",
        "#FFCD00",
        "#3FBC87",
        "#5197FF",
        "#BADCFF",
        "#000000",
      ],
    }
  },
  // watch: {
  //   martName: {
  //     handler(val) {
  //       this.curPage.martName = val
  //     }
  //   }
  // },
  methods: {
    handleChangeTitleMode(val) {
      this.$set(this.curPage, 'ShowMartNameType', val)
      if (val == '1') {
        this.handleChangeNameType('1')
      }
    },
    handleChangeNameType(val) {
      const list = [
        {
          value: '1',
          label: this.project.Name
        },
        {
          value: '2',
          label: this.curPage.name
        },
        {
          value: '3',
          label: ''
        },
      ]
      const _name = list.find(f => f.value == val)?.label || ''
      this.$set(this.curPage, 'martName', _name)
      // this.martName = _name
      // this.curPage.martName = list.find(f => f.value == val)?.label

    },
    handleCustomize() {
      this.curPage.ThemeColor = ''
      this.curPage.ThemeImg = ''
    },
    handleSelect() { },
    /**打开文件选择弹框 */
    handleSelectFile() {
      this.$nextTick(() => {
        this.$refs["uploadDiaRef"].open()
      })
    }
  },
};
</script>
<style lang="scss" scoped>
:deep(.el-color-picker__trigger) {
  width: 56px;
  height: 26px;
}

.wrap {
  background: #fff;

  &:last-child {
    padding-bottom: 30px;
  }

  .el-radio {
    margin-right: 15px;
  }

  .el-color-picker {
    display: flex;
    align-items: center;
    margin-top: 10px;
    // margin: 10px 0;
  }

  .wrap-label {
    padding: 10px 12px;
    background: #e8f0fb40;
    font-size: 14px;
    color: #323233;
    font-weight: 550;

    span {
      &::before {
        content: ".";
        width: 3px;
        height: 10px;
        margin-right: 8px;
        background: $color-theme;
      }
    }
  }

  .wrap-body {
    padding: 10px 20px 10px 20px;

    .set-wrap {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>